.listing-card_carousel .slick-arrow {
  top: 50%;
  transform: translateY(-50%);
  z-index: 30;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: white;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 100ms;
}

.listing-card:hover .slick-arrow {
  opacity: 0.5;
}

.listing-card_carousel .slick-arrow:hover {
  opacity: 1;
}

.listing-card_carousel .slick-prev {
  left: 15px;
}

.listing-card_carousel .slick-next {
  right: 15px;
}

.listing-card_carousel .slick-prev::before,
.listing-card_carousel .slick-next::before {
  content: "";
  font-family: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px !important;
  height: 12px !important;
}

.listing-card_carousel .slick-prev::before {
  background-image: url("/images-carousel-prev-btn.png");
}

.listing-card_carousel .slick-next::before {
  background-image: url("/images-carousel-next-btn.png");
}

.listing-card_carousel .slick-dots {
  bottom: 15px;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.tagged-listing .slick-dots {
  bottom: 45px !important;
}

.listing-card_carousel .slick-dots li {
  width: 0px !important;
  height: 0px !important;
  margin: 0 6px !important;
  background-color: transparent;
}

.listing-card_carousel .slick-dots li button {
  width: 5px !important;
  height: 5px !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
  width: 0px !important;
  height: 0px !important;
}

.slick-dots li button:before {
  opacity: 0.5;
  color: white;
  width: 0px !important;
  height: 0px !important;
}
